<template>
  <div class="content" style="height: calc(100vh - 165px);">
    <el-row>
      <el-col :span="5" style="border-right:1px solid #F1F1F1">
        <div class="TreeBox">
          <div class="detil-code">片区查看</div>
          <div class="clears">
            <el-input
                v-model="filterText"
                prefix-icon="el-icon-search"
                size="mini"
                placeholder="输入关键字进行过滤"
                clearable
            >
            </el-input>
            <el-button size="mini" class="buttons" type="primary"
            >搜索</el-button
            >
          </div>
          <el-tree
              ref="tree"
              node-key="id"
              class="filter-tree"
              :data="treedata"
              :highlight-current='true'
              :props="defaultProps"
              :default-expanded-keys="[8]"
              :default-expand-all="false"
              :filter-node-method="filterNode"
              @node-click="(data,node) => click(data,node)"
          >
          </el-tree>
        </div>
      </el-col>
      <el-col :span="19" v-if="!dialogVisible">
        <div class="TableBox">
          <el-form class="SearchBox" :inline="true">
            <el-form-item label="用户分类">
              <el-select
                  v-model="pageParams.userType"
                  placeholder="请选择"
                  size="mini"
                  clearable
                  filterable
                  @change="search"
              >
                <el-option
                    v-for="(item, index) in userTypeList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="异常用户">
              <el-date-picker
                  v-model="time"
                  size="mini"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  range-separator="至"
                  @change="setDate"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>

            <!-- <el-select
                v-model="chooseType"
                style="width: 100px; margin: 6px 0"
                placeholder="请选择"
                size="mini"
                @change="chooseTypeHandle"
            >
              <el-option
                  v-for="item in dataList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input v-if="chooseType==1" v-model="pageParams.userName" size="mini" placeholder="请输入关键字" style="width: 150px;margin-right: 10px;margin-top: 6px"></el-input>
            <el-input v-if="chooseType==2" v-model="pageParams.userPhone" size="mini" placeholder="请输入关键字" style="width: 150px;margin-right: 10px;margin-top: 6px"></el-input>
            <el-input v-if="chooseType==3" v-model="pageParams.contactName" size="mini" placeholder="请输入关键字" style="width: 150px;margin-right: 10px;margin-top: 6px"></el-input>
            <el-input v-if="chooseType==4" v-model="pageParams.address" size="mini" placeholder="请输入关键字" style="width: 150px;margin-right: 10px;margin-top: 6px"></el-input>
            <el-input v-if="chooseType==5" v-model="pageParams.sheetSteel" size="mini" placeholder="请输入关键字" style="width: 150px;margin-right: 10px;margin-top: 6px"></el-input> -->
            <el-form-item label="" label-width="0px" class="selectType">
							<el-input
								placeholder="请输入关键字"
                size="mini"
								v-model="pageParams[keyword]"
							>
								<el-select
									placeholder="请选择"
									v-model="chooseType"
									slot="prepend"
                  filterable
                  @change="chooseTypeHandle"
								>
									<el-option
                    v-for="item in dataList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
              </el-option>
								</el-select>
							</el-input>
						</el-form-item>
            <el-button
                size="mini"
                type="primary"
                @click="search"
                style="height: 30px; margin-top: 5px"
            >查询</el-button
            >
            <el-button
                size="mini"
                style="height: 30px; margin-top: 5px"
                @click="restSearch"
                type="warning"
            >重置</el-button
            >
            <el-button
                v-if="$anthButtons.getButtonAuth('myyhdc')"
                size="mini"
                @click="exportUser"
                type="text"
                style="
                border: 1px solid #1082ff;
                color: #1082ff;
                padding: 0 14px;
                height: 30px;
                margin-top: 5px;
              "
                plain
            >
              导出</el-button
            >
          </el-form>
          <div class="TableTool" style="margin-top: 10px">
            <div class="TableToolLeft">
              <!-- <el-button  size="mini" @click="addUser">添加</el-button> -->
              <el-button
                  v-if="$anthButtons.getButtonAuth('yonghuplsc')"
                  size="mini"
                  @click="del(0)"
                  type="text"
                  style="
                  border: 1px solid #f56c6c;
                  color: #f52424;
                  padding: 0 14px;
                  height: 30px;
                "
                  plain
              >批量删除</el-button
              >
              <!-- <el-button
                  v-if="$anthButtons.getButtonAuth('myyhplsc')"
                  size="mini"
                  @click="delALL( 1)"
                  type="text"
                  style="
                  border: 1px solid #f56c6c;
                  color: #f52424;
                  padding: 0 14px;
                  height: 30px;
                "
                  plain
              >全部删除</el-button
              > -->
              <el-button
                  v-if="$anthButtons.getButtonAuth('myyhplcl')"
                  size="mini"
                  @click="batch"
                  type="text"
                  style="
                  border: 1px solid #1082ff;
                  color: #1082ff;
                  padding: 0 14px;
                  height: 30px;
                "
                  plain
              >批量处理</el-button
              >
            </div>
            <div class="TableToolRight">
              <el-button
                  v-if="$anthButtons.getButtonAuth('myyhtj')"
                  size="mini"
                  @click="addUser"
                  type="text"
                  style="
                  border: 1px solid #1082ff;
                  color: #1082ff;
                  padding: 0 14px;
                  height: 30px;
                "
                  plain
              >添加</el-button
              >
              <!-- <el-upload
                  v-if="$anthButtons.getButtonAuth('myyhdr')"
               class="upload-demo"
                  ref="upload"
                  :http-request="handlePreview"
                  accept=".xls,.xlsx"
                  action="#"
                  :auto-upload="true"
                  :show-file-list="false"
              >
                <el-button slot="trigger" style="margin: 0 10px;border: 1px solid #1082ff;color: #1082ff;padding: 0 14px;height: 30px;" plain>导入用户档案</el-button>
              </el-upload> -->
              <!--              <el-button-->
              <!--                size="mini"-->
              <!--                @click="fileDialog = true"-->
              <!--                type="text"-->
              <!--                style="border: 1px solid #1082ff;color: #1082ff;padding: 0 14px;height: 30px;"-->
              <!--                plain-->
              <!--                >导入用户档案</el-button-->
              <!--              >-->

              <!-- <el-button @click="download"
                         size="mini"
                         type="text"
                         style="
                  border: 1px solid #1082ff;
                  color: #1082ff;
                  padding: 0 14px;
                  height: 30px;
                "
                         plain
                         v-if="$anthButtons.getButtonAuth('xzdrmb')"
              >下载导入模板</el-button
              > -->
            </div>
          </div>
          <el-table
              :data="userList"
              height="calc(100vh - 380px)"
              style="margin-top: 10px"
              border
              :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              background: '#f8f8f9',
              textAlign:'left',
            }"
              @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="50"> </el-table-column>
            <el-table-column
                type="index"
                label="序号"
                width="60"
            ></el-table-column>
            <el-table-column
                prop="residentName"
                label="户主姓名"
            >
              <template slot-scope="{row}">
                <div  slot="reference" class="twoLines">
                  {{row.residentName}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
                prop="mobile"
                label="户主电话"
                width="110"
            >
              <template slot-scope="{row}">
                <div  slot="reference" class="twoLines">
                  {{row.mobile}}
                </div>
              </template>
            </el-table-column>
            <el-table-column
                prop="userTypeName"
                show-overflow-tooltip
                label="用户分类"
                width="100"
            ></el-table-column>
            <el-table-column prop="addrName" label="片区" width="350">
              <template slot-scope="{row}">
                <el-tooltip  class="item" placement="top-start" effect="dark" :content="row.addrName+'->'+row.addrsName+'->'+row.addrssName">
                  <!-- <div  slot="reference" class="twoLines"> -->
                  <div class="twoLines"> {{row.addrName}}->{{row.addrsName}} -> {{row.addrssName}}</div>
                  <!-- </div> -->
                </el-tooltip>

                <!-- <el-popover placement="top-start" trigger="hover" :content="row.deptName">
                  <div  slot="reference" class="twoLines">
                    {{row.deptName}}
                  </div>
                </el-popover> -->

              </template>
            </el-table-column>
            <el-table-column prop="address" label="地址" width="350">
              <template slot-scope="{row}">
                <el-tooltip class="item" placement="top-start" effect="dark"  :content="row.address">
                  <!-- <div  slot="reference" class="twoLines"> -->
                  <div class="twoLines">{{row.address}}</div>
                  <!-- </div> -->
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
                prop="sheetSteel"
                label="表编号"
                width="120"
            >
            </el-table-column>
            <!-- <el-table-column
                prop="tableTypeName"
                label="燃气表类型"
                width="90"
            >
            </el-table-column>
            <el-table-column
                prop="tableDirectionName"
                label="表向"
            >
            </el-table-column>
            <el-table-column
                prop="tableVenderName"
                label="燃气表厂家"
                width="90"
            >
            </el-table-column>
            <el-table-column
                prop="tableTitles"
                label="表封号">
            </el-table-column>
            <el-table-column
                prop="openDate"
                label="开户日期"
            width="100">
            </el-table-column>
            <el-table-column
                prop="receiveStove"
                width="100"
                label="所接炉具">
            </el-table-column>
            <el-table-column
                prop="remark"
                label="备注"
            >
              <template slot-scope="{row}">
                <el-tooltip class="item" placement="top-start" effect="dark"  :content="row.remark">
                  <div class="twoLines">{{row.remark}}</div>
                </el-tooltip>
              </template>
            </el-table-column> -->
            <el-table-column
                prop="deptName"
                label="负责部门"
                width="90">
            </el-table-column>
            <el-table-column
                prop="lastTime"
                label="最新安检时间"
                width="160">
            </el-table-column>
            <el-table-column
                prop="safetyResults"
                label="安检结果">
              <template slot-scope="{row}">
                <span v-if="row.safetyResults==1">正常安检</span>
                <span v-if="row.safetyResults==2">到访不遇</span>
                <span v-if="row.safetyResults==3">拒绝安检</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="inspectorName"
                label="安检员">
            </el-table-column>
            <el-table-column
                prop="State"
                label="操作"
                width="180px"
                fixed="right"
            >
              <template slot-scope="{ row }">
                <div class="TableActionBar">
                  <el-button type="primary" size="mini" @click="exchange('查看',row)" style="margin: 0 10px" v-if="$anthButtons.getButtonAuth('myyhck')">查看</el-button>
                  <el-dropdown @command="(command) => exchange(command, row)">
                    <el-button type="text" size="mini" style="
                        border: 1px solid #1082ff;
                        color: #1082ff;
                        padding: 0 11px;
                        height: 30px;
                      ">更多<i class="el-icon-caret-bottom el-icon--right"></i></el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="编辑" v-if="$anthButtons.getButtonAuth('myyhbj')">编辑</el-dropdown-item>
                      <el-dropdown-item command="换表" v-if="$anthButtons.getButtonAuth('myyhhb')">换表</el-dropdown-item>
                      <el-dropdown-item command="启用/停用" v-if="$anthButtons.getButtonAuth('myyhqyty')">{{row.generalStatus==1?'停用':'启用'}}</el-dropdown-item>
                      <el-dropdown-item command="编辑记录" v-if="$anthButtons.getButtonAuth('myyhbjjl')">编辑记录</el-dropdown-item>
                      <el-dropdown-item command="换表记录" v-if="$anthButtons.getButtonAuth('myyhhbjl')">换表记录</el-dropdown-item>
                      <el-dropdown-item command="安检记录" v-if="$anthButtons.getButtonAuth('myyhajjl')">安检记录</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div style="padding: 20px 0">
            <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :page-sizes="[30, 50, 100]"
                :page-size="pageParams.size"
                :current-page="pageParams.current"
                @size-change="handleSizeChange"
                @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 批量删除 -->
    <el-dialog
        :visible.sync="dialogBatch"
        width="20%"
        :close-on-click-modal="false"
        @close="handleClose"
    >
      <el-form class="SearchBox" ref="Batchform" :model="Batchform" :rules="batchRule">
        <el-form-item label="区域" label-width="80px" prop="addr">
          <el-cascader
              style="width: 100%"
              size="mini"
              v-model="Batchform.addr"
              :options="areaList"
              :props="{ expandTrigger: 'hover',label:'name',value:'trueId',disabled: 'disable' }"></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button style="margin-right: 10px" @click="dialogBatch = false">取消</el-button>
          <el-button type="primary" @click="submitBatch('Batchform')">确定</el-button>
      </span>
    </el-dialog>
    <!-- 添加用户 -->
    <el-dialog
        :title="userTitle"
        :visible.sync="addUserDialog"
        width="1020px"
        :close-on-click-modal="false"
        @close="UserClose"
    >
    <peopleUserEdit ref="peopleUserEdit" @backtrack='searchuser'></peopleUserEdit>
    </el-dialog>
    <!-- 地图组件 -->
    <el-dialog
        :visible.sync="showModel"
        :show-close="false"
        :close-on-click-modal="false"
    >
      <mapDialog ref="mapDialog" @closeModal="closeModel" :newForm="newForm" :point="point"></mapDialog>
    </el-dialog>
    <!-- 导出中 -->
    <el-dialog title="导出中..." :visible.sync="deriveVisible" width="30%">
      <el-table v-loading="loading" empty-text="导出中"> </el-table>
      <span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
			</span>
    </el-dialog>
    <!-- 换表 -->
    <el-dialog
        title="换表"
        :close-on-click-modal="false"
        :visible.sync="exchangeDialog"
        width="520px"
        @close="exchangeClose">
      <el-form class="SearchBox" ref="exchangeFrom" :model="exchangefrom" :rules="exchangerule">
        <el-form-item label="旧表机械数：" label-width="145px" prop="oldMechanicalData">
          <el-input v-model="exchangefrom.oldMechanicalData" placeholder="请输入内容" style="width:308px" :maxlength='30'></el-input>
        </el-form-item>
        <el-form-item label="新表机械数：" label-width="145px" prop="newMechanicalData">
          <el-input v-model="exchangefrom.newMechanicalData" placeholder="请输入内容" style="width:308px" :maxlength='30'></el-input>
        </el-form-item>
        <el-form-item label="表编号：" label-width="145px" prop="sheetSteel" >
          <el-input v-model="exchangefrom.sheetSteel" placeholder="请输入内容" style="width:308px" :maxlength='30'></el-input>
        </el-form-item>
        <el-form-item label="燃气表类型：" label-width="145px" prop="tableType">
          <el-select v-model="exchangefrom.tableType" placeholder="请选择" filterable style="width:308px">
            <el-option
                v-for="(item, index) in tableTypeList"
                :key="index"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="表向：" label-width="145px" prop="tableDirection" >
          <el-select v-model="exchangefrom.tableDirection" placeholder="请选择" filterable style="width:308px">
            <el-option
                v-for="(item, index) in tableDirectionList"
                :key="index"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="燃气表厂家：" label-width="145px" prop="tableVender">
          <el-select v-model="exchangefrom.tableVender" placeholder="请选择" filterable style="width:308px">
            <el-option
                v-for="(item, index) in tableVenderList"
                :key="index"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="表封号：" label-width="145px" prop="tableTitles">
          <el-input v-model="exchangefrom.tableTitles" placeholder="请输入"  style="width:308px" :maxlength='30'></el-input>
        </el-form-item>
        <el-form-item label="剩余气量："  label-width="145px" prop="residualVolume" >
          <el-input v-model="exchangefrom.residualVolume" placeholder="请输入"  style="width:308px" :maxlength='30'></el-input>
        </el-form-item>
        <el-form-item label="换表人：" label-width="145px" prop="updateTableBy">
          <el-input v-model="exchangefrom.updateTableBy" placeholder="请输入"  style="width:308px" :maxlength='30'></el-input>
        </el-form-item>
        <el-form-item label="换表日期：" label-width="145px" prop="updateTableTime">
          <el-date-picker
              v-model="exchangefrom.updateTableTime"
              type="date"
              value-format="yyyy-MM-dd"
              style="width:308px"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button style="margin-right: 10px" @click="exchangeDialog = false">取消</el-button>
          <el-button type="primary" @click="submitExchange('exchangeFrom')">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectDictListByPid
} from "@/RequestPort/business/userFile.js";
import {
  inspectorDropDown,
  userInsertOrUpdate,
} from "@/RequestPort/induction";
import getNowFormatDate from "@/common/js/nowDate";
import {selectInspectorsByAddr} from "../../RequestPort/induction";
import { exportMethod } from '../../common/js/exportExcel'
import { formatTime } from '../../utils/common.js'
import peopleUserEdit from "./comList/peopleUserEdit";
import {
  userBatchSet,findTree,userPageList,addrDropDown,importUserExcel,
  userAllDelete,userBatchDelete,tableWithReplace,exportUserExcel,
  updateUserGenerateStatus} from "../../RequestPort/peopleUser/people";
export default {
  name: "User",
  components: { peopleUserEdit },
  data() {
    return {
      keyword:'userName',
      chooseType:1,
      // 换表弹窗
      exchangeDialog:false,
      exchangefrom: {
        residualVolume:'',
        oldMechanicalData:'',
        newMechanicalData:0,
        sheetSteel:'',
        tableType:'',
        tableDirection:'',
        tableVender:'',
        tableTitles:'',
        updateTableBy:'',
        updateTableTime: formatTime('yyyy-MM-dd', new Date()),
      },
      exchangerule:{
        residualVolume:{ required: true, message: "请输入", trigger: "change" },
        oldMechanicalData:{ required: true, message: "请输入", trigger: "change" },
        newMechanicalData:{ required: true, message: "请输入", trigger: "change" },
        sheetSteel:{ required: true, message: "请输入", trigger: "change" },
        tableType:{ required: true, message: "请选择", trigger: "change" },
        tableDirection:{ required: true, message: "请选择", trigger: "change" },
        tableVender:{ required: true, message: "请选择", trigger: "change" },
        updateTableBy:{ required: true, message: "请输入", trigger: "change" },
        updateTableTime:{ required: true, message: "请输入", trigger: "change" }
      },
      newForm:'',
      point:'',
      
      dataList:[
        {label:"户主姓名",value:1},{label:"电话",value:2},{label:"安检联系人",value:3},{label:"地址",value:4},{label:"表编号",value:5}
      ],
      time:[],
      userTypeList:[],
      peopleList:[],
      areaList:[],
      statusList:[
        {id:1,name:'已启用'},{id:2,name:'停用安检'},{id:3,name:'停用不安检'},{id:4,name:"已销户"}
      ],
      pageParams: {
        current: 1,
        size: 100,
        userType:'',
        contactName: "",
        userName: "",
        contact: '',
        userPhone:'',
        address:'',
        sheetSteel:''
      },
      updataUserId: null,
      form: {
        userPhone:"",
        contact:'',
        userIdnumber:"",
        businessLicenseNumber:'',
        userName:'',
        addrId:'',
        address:'',
        userId: "",
        userType: '',
      },
      Batchform:{
        addr:[],
      },
      streetList: [],
      updataStatus: 0,
      dialogVisible: false,
      total: null,
      // 列表数据
      userList: [{ name: "11", phone: 1 }],
      userStatus: [
        { label: "启用", id: "1" },
        { label: "禁用", id: "0" },
      ],
      roleArray_group: [],
      filterText: "",
      treedata: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      batchRule:{
        addr: [
          { required: true, message: "请选择", trigger: "blur" },
        ]
      },
      rule: {
        userName: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
          { max: 25, message: "超出最大限制", trigger: "change" },
        ],
        contact: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { max: 25, message: "超出最大限制", trigger: "change" },
        ],
        addrId: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        userIdnumber: [
          {
            message: "请输入正确的身份证号",
            trigger: ["change"],
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, },
        ],
        address: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        userType: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        userId: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        userPhone: [
          { required: true,
            message: "请输入手机号",
            trigger: "blur" },
          {
            message: "请输入正确的手机号",
            trigger: ["change"],
            pattern: /^1(3|4|5|7|8|6|9|)\d{9}$/ },
        ],
      },
      // 批量删除弹窗
      dialogBatch: false,
      deriveVisible: false,
      loading:true,
      // 批量删除对象
      from:{},
      recursion: [],
      tableDataList: [],
      fileList: [],
      fileDialog: false,
      // 复选的选中数据
      checkAll: [],
      // 地图组件
      showModel: false,
      // 添加用户弹窗
      addUserDialog: false,
      // 用户弹窗
      userTitle: "添加用户",
      tableDirectionList:[],
      tableTypeList:[],
      tableVenderList:[],
      districtids:[]
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    '$store.getters.deriveVisible'(val) {
        this.deriveVisible = val
    },
  },
  mounted() {
    // this.getRoleIdsDic();
    //  获取树
    findTree().then(res=>{
      this.treedata = res.data
      this.treedata.forEach(el => {
          if(el.type != 4 && el.children && el.children.length > 0) {
              el.children.forEach(el1 => {
                  if(el1.type != 4 && el1.children && el1.children.length > 0) {
                      el1.children.forEach((el2,index1) => {
                          if(el2.type != 4 && el2.children && el2.children.length > 0) {
                              el2.children.forEach((el3,index) => {
                                  if(el3.type == 4) {
                                      el3.name = (index+1)+'.'+el3.name
                                  }
                              })
                          }
                          else if(el2.type == 4) {
                              el2.name = (index1+1)+'.'+el2.name
                          }
                      })
                  }
              })
          }
      })
      this.pageParams.homeCode = res.data[0].id
      this.loadList(this.pageParams)
    })
    // 获取用户分类
    selectDictListByPid({pid: 732}).then(res => {
      this.userTypeList = res.data
    })
    inspectorDropDown().then(res=>{
      this.peopleList = res.data
    })
    addrDropDown().then(res=>{
      res.data.forEach(item=>{
        if(item.children){
          item.disable = false
          item.children.forEach(el => {
							if(el.children){
								el.disable = false
							} else {
								el.disable = true
							}
						})
        }else {
          item.disable = true
        }
        this.areaList = res.data
      })
    })
    this.getOption()
  },
  methods: {
    submitExchange(exchangeFrom){
      this.$refs[exchangeFrom].validate((valid) => {
        if (!valid) return false
        tableWithReplace(this.exchangefrom).then(res=>{
          if(res.code===200){
            this.exchangeDialog = false
            this.$message.success(res.data)
            this.loadList(this.pageParams)
          }else {
            this.$message.error(res.data)
          }
        })

      })
    },
    getOption(){
      selectDictListByPid({pid: 707}).then(res => {
        this.tableDirectionList = res.data
      })
      selectDictListByPid({pid: 702}).then(res => {
        this.tableTypeList = res.data
      })
      selectDictListByPid({pid: 710}).then(res => {
        this.tableVenderList = res.data
      })
    },
    /**
     * 换表关闭事件
     */
    exchangeClose(){
      this.exchangefrom = {
        residualVolume:'',
        oldMechanicalData:'',
        newMechanicalData:0,
        sheetSteel:'',
        tableType:'',
        tableDirection:'',
        tableVender:'',
        tableTitles:'',
        updateTableBy:'',
        updateTableTime:formatTime('yyyy-MM-dd', new Date()),
      }
      this.$refs.exchangeFrom.resetFields()
      this.exchangeDialog = false
    },
    chooseTypeHandle(val){
      if(val==1){
        this.keyword = 'userName'
        this.pageParams.userPhone=''
        this.pageParams.contactName=''
        this.pageParams.address=''
        this.pageParams.sheetSteel=''
      }else if(val==2){
        this.keyword = 'userPhone'
        this.pageParams.userName=''
        this.pageParams.contactName=''
        this.pageParams.address=''
        this.pageParams.sheetSteel=''
      }else if(val==3){
        this.keyword = 'contactName'
        this.pageParams.userPhone=''
        this.pageParams.userName=''
        this.pageParams.address=''
        this.pageParams.sheetSteel=''
      }else if(val==4){
        this.keyword = 'address'
        this.pageParams.userPhone=''
        this.pageParams.contactName=''
        this.pageParams.userName=''
        this.pageParams.sheetSteel=''
      }else if(val==5){
        this.keyword = 'sheetSteel'
        this.pageParams.userPhone=''
        this.pageParams.contactName=''
        this.pageParams.address=''
        this.pageParams.userName=''
      }
    },
    setDate(e){
      if (e) {
        this.pageParams.startTime = this.time[0]+ ' 00:00:00'
        this.pageParams.endTime = this.time[1]+ ' 23:59:59'
      } else {
        this.pageParams.startTime = ''
        this.pageParams.endTime = ''
      }
    },
    fillterName(name){
      return name.split('(')
    },
    clickAddress(){
      this.showModel = true
      if(this.form.address){
        this.newForm=this.form.address
        this.point=this.form.addressCoord
        console.log(this.form.addressCoord)
        this.$nextTick(()=>{
          this.$refs.mapDialog.openL(this.form.addressCoord)
        })

      }
    },
    /**
     * 导出
     */
    exportUser(){
      let arr = []
      this.checkAll.forEach(item=>{
        arr.push(item.id)
      })
      this.deriveVisible = true
      this.$store.commit('setderiveVisible',true)
      // let obj = {
      //   userType:this.pageParams.userType,
      //   contactName:this.pageParams.contactName,
      //   contact: this.pageParams.contact,
      //   userPhone:this.pageParams.userPhone,
      //   address:this.pageParams.address,
      //   sheetSteel:this.pageParams.sheetSteel,
      //   userIds:arr
      // }
      exportUserExcel(this.pageParams).then(res=>{
        exportMethod(res, '居民用户')
        this.deriveVisible = false
      })
    },
    cancel(){
      this.deriveVisible = false
      this.$store.commit('setderiveVisible',false)
      window.stop()
    },
    showTable(){
      this.fileDialog = false
      this.loadList(this.pageParams)
    },
    async handlePreview(file) {
      let uploadObj = new FormData();
      uploadObj.append("file", file.file);
      try {
        let { data } = await importUserExcel(uploadObj);
        this.tableDataList = data;
        this.loadList(this.pageParams)
      } catch (e) {
        this.tableDataList = []
      }
    },
    /**
     * 下载导入模板
     */
    download(){
      const elink = document.createElement("a");

      // elink.href ="https://gas-dev.oss-cn-beijing.aliyuncs.com/ZYMenuIcon/mymb.xlsx";
      elink.href ="https://gas-dev.oss-cn-beijing.aliyuncs.com/THZL/mymb.xlsx";
      const fileName = getNowFormatDate() + ".xls";

      elink.setAttribute("download", fileName);

      elink.style.display = "none";

      document.body.appendChild(elink);

      setTimeout(() => {
        elink.click();

        document.body.removeChild(elink);
      }, 66);
    },
    submitBatch(form){
      this.$refs[form].validate((valid) => {
        if (!valid) return false
        let arr = []
        this.checkAll.forEach(item=>{
          arr.push(item.id)
        })
        this.Batchform.userIds = arr
        this.Batchform.addrId = this.Batchform.addr[0]
        this.Batchform.addrsId = this.Batchform.addr[1]
        this.Batchform.addrssId = this.Batchform.addr[2]
        userBatchSet(this.Batchform).then(res=>{
          if(res.code===200){
            this.$message.success(res.data)
            this.dialogBatch = false
            this.loadList(this.pageParams)
          }else{
            this.$message.error(res.data)
          }
        })
      })
    },
    /**
     * 换表
     */
    exchange(command, row) {
      if (command == "查看") {
        let obj = JSON.stringify(row)
        // this.$router.push({
        //   path: "/home/record/peopleUserEditDetails",
        //   query: { obj:obj },
        // });
        let chakan = this.$router.resolve({
					path: '/home/record/peopleUserEditDetails',      //跳转目标窗口的地址
					query: { obj:obj } 
				})
				window.open(chakan.href);
      }
      if (command == "编辑") {
        let obj = JSON.stringify(row)
        // this.$router.push({
        //   path: "/home/record/peopleUserEdit",
        //   query: { obj:obj },
        // });
        // let chakan = this.$router.resolve({
				// 	path: '/home/record/peopleUserEdit',      //跳转目标窗口的地址
				// 	query: { obj:obj } 
				// })
				// window.open(chakan.href);
        this.userTitle = "编辑用户";
        this.addUserDialog = true;
        this.$nextTick(() => {
           this.$refs.peopleUserEdit.openEdit(obj)
        })
       
      }
      if (command == "换表") {
        this.$set(this.exchangefrom,'pid',row.tableWithId)
        this.exchangeDialog = true
      }
      if (command == "启用/停用") {
        console.log(row.generalStatus)
        updateUserGenerateStatus({
          status: row.generalStatus == 1 ? 0 : 1,
          userId: row.id
        }).then(res => {
          if (res.code === 200) {
            this.$message.success(res.data);
          } else {
            this.$message.error(res.data);
          }
          this.loadList(this.pageParams)
        })
      }
      if (command == "换表记录") {
        // this.$router.push({
        //   path: "/home/record/recordTable",
        //   query: { id: row.id,type:'user' },
        // });
        let chakan = this.$router.resolve({
					path: '/home/record/recordTable',      //跳转目标窗口的地址
					query: { id: row.id,type:'user' }
				})
				window.open(chakan.href);
      }
      if(command == '编辑记录') {
        // this.$router.push({
        //   path: "/home/record/peopleEdit",
        //   query: { userId: row.id },
        // });
        let chakan = this.$router.resolve({
					path: '/home/record/peopleEdit',      //跳转目标窗口的地址
					query: { userId: row.id }
				})
				window.open(chakan.href);
      }
      if(command == '安检记录') {
        // this.$router.push({
        //   path: "/home/record/peopleSecurity",
        //   query: { id: row.id },
        // });
        let chakan = this.$router.resolve({
					path: '/home/record/peopleSecurity',      //跳转目标窗口的地址
					query:{ id: row.id }
				})
				window.open(chakan.href);
      }
    },
    batch() {
      let that = this
      if (this.checkAll.length == 0) {
        that.$message.error("请先勾选数据");
        return;
      }
      this.dialogBatch = true;
    },
    /**
     *    功能描述： 跳转页面
     *    开发人：why
     *    开时间：2022.5.17
     */
    goPath(path, id, disable) {
      this.$router.push({ path: path, query: { id: id, disable: disable } });
    },
    /**
     *    功能描述： 批量删除关闭弹窗
     *    开发人：why
     *    开时间：2022.5.5
     */
    handleClose() {
      this.Batchform = {
        addr:[],
      };
      inspectorDropDown().then(res=>{
        this.peopleList = res.data
      })
      this.dialogBatch = false;
    },
    /**
     *    功能描述： 添加用户关闭弹窗
     *    开发人：why
     *    开时间：2022.5.17
     */
    UserClose() {
      // this.form = {
      //   userPhone:"",
      //   contact:'',
      //   userIdnumber:"",
      //   businessLicenseNumber:'',
      //   userName:'',
      //   addrId:'',
      //   address:'',
      //   userId: "",
      //   userType: '',
      // };
      // if(this.$refs.form){
      //   this.$refs.form.clearValidate()
      // }
        this.$refs.peopleUserEdit.clace()
      this.addUserDialog = false;
    },
    /**
     * 新增编辑用户后返回刷新
     */
    searchuser(){
      this.loadList(this.pageParams)
      this.addUserDialog = false;
    },
    /**
     *    功能描述： 地图选择位置
     *    开发人：why
     *    开时间：2022.5.16
     */
    closeModel(address, lnglat) {
      // this.showModel = false;
      if (!address && !lnglat) {
        this.showModel = false;
      } else {
        console.log(lnglat);
        this.form.lat = lnglat[1];
        this.form.lng = lnglat[0];
        this.form.address = address;
        this.showModel = false;
        this.$forceUpdate();
      }
    },
    handleChange(value) {
      console.log(value);
    },
    /**
     *    功能描述： 全选
     *    开发人：why
     */
    handleSelectionChange(val) {
      this.checkAll = val;
    },
    recursionFun(e, pid) {
      if (e.children) {
        e.children.forEach((item, index) => {
          this.recursionFun(item, e.id);
        });
        this.recursion.push(e);
      } else {
        this.recursion.push(e);
        return;
      }
    },
    // 重置查询条件
    restSearch() {
      this.pageParams = {
        current: 1,
        size: 100,
        userType:'',
        contactName: "",
        userName: "",
        contact: '',
        userPhone:'',
        address:'',
        sheetSteel:''
      };
      this.time = []
      this.loadList(this.pageParams);
    },
    // 返回
    close(val) {
      this.dialogVisible = !this.dialogVisible; //监听值发生变化就关闭它
    },
    /**
     * 批量处理
     */
    del(val) {
      let that = this
      if (this.checkAll.length == 0 && !val) {
        that.$message.error("请先勾选数据");
        return;
      }
      let text = val ? "是否确认删除列表中所有数据" : "是否确认删除所选数据";
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        let arr =[]
        this.checkAll.forEach(item=>{
          arr.push(item.id)
        })
        if(val){
          userBatchDelete([]).then(res=>{
            this.loadList(this.pageParams);
          })
        }else {
          userBatchDelete(arr).then((e) => {
            if (e.code === 200) {
              that.$message.success(e.data);
            } else {
              that.$message.error(e.data);
            }
            this.loadList(this.pageParams);
          }).catch(arr => {
            this.loadList(this.pageParams);
          });
        }

      });
    },
    /**
     * 全部删除
     */
    delALL(val){
      let that = this
      if (this.checkAll.length == 0 && !val) {
        that.$message.error("请先勾选数据");
        return;
      }
      let text = val ? "是否确认删除列表中所有数据" : "是否确认删除所选数据";
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        userAllDelete(this.pageParams).then((e) => {
          if (e.code === 200) {
            that.$message.success(e.data);
          } else {
            that.$message.error(e.data);
          }
          this.loadList(this.pageParams);
        }).catch(arr => {
          this.loadList(this.pageParams);
        });
      });
    },
    updataUser(row) {
      this.updataStatus = 1;
      this.updataUserId = row.id;
      findUserById({ id: row.id }).then((res) => {
        this.form = res.data;

        console.log(this.form, this.roleArray_group);
      });
      this.dialogVisible = true;
    },
    submitfrom(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) return false;
        let ajaxData = {};
        this.form.roleIds = Array.from(new Set(this.form.roleIds)); //去重
        Object.assign(ajaxData, this.form);
        ajaxData.homeCode = this.form.homeCode;
        saveUser(ajaxData)
            .then((e) => {
              if (e.code === 200) {
                this.$message.success(e.msg);
                this.dialogVisible = false;
                this.loadList(this.pageParams);
              } else {
                this.$message.error(e.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
      });
    },
    updatasubmitfrom(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) return false;
        let ajaxData = {};
        this.form.roleIds = Array.from(new Set(this.form.roleIds)); //去重
        ajaxData.homeCode = this.form.homeCode;
        Object.assign(ajaxData, this.form);
        updateUser(ajaxData).then((e) => {
          if (e.code === 200) {
            this.$message.success(e);
            this.dialogVisible = false;
            this.loadList(this.pageParams);
          } else {
            this.$message.error(e);
          }
        });
      });
    },
    /**
     * 添加用户弹窗按钮
     */
    addUser() {
      let roleIds = localStorage.getItem("roleIds").split(',')
      let index = roleIds.findIndex(el => el == '1')
      if(index >= 0) {
        this.$message.info('系统管理员禁止添加！')
        return
      }
      // this.$router.push({
      //   path: "/home/record/peopleUserEdit"
      // });
      let data = {}
      if(this.districtids.length >= 2 ) {
         data = {...this.pageParams,
                addrId:this.districtids[0].value,
                addrsId:this.districtids[1].value,
                addrssId:this.districtids[2].value,
                address:this.districtids[0].name.split('.')[1]+'-'+this.districtids[1].name+'-'+this.districtids[2].name
              }
      }
      // let chakan = this.$router.resolve({
			// 		path: '/home/record/peopleUserEdit',
      //     query: this.districtids.length >= 2 ? { obj: JSON.stringify(data)} : {},
			// 	})
			// 	window.open(chakan.href);
      delete data.userType
      this.userTitle = "添加用户";
      this.addUserDialog = true;
      this.$nextTick(() => {
           this.$refs.peopleUserEdit.openEdit(JSON.stringify(data))
        })
      //  if(this.$refs.peopleUserEdit){
      //   this.$refs.peopleUserEdit.ruleForm= {
			// 		address:'',
			// 		sheetSteel:'',
			// 		tableType:'',
			// 		tableDirection:'',
			// 		tableVender:''
			// 	}
      //   if( this.$refs.peopleUserEdit.$refs.ruleForm) {
      //     this.$refs.peopleUserEdit.$refs.ruleForm.clearValidate()
      //   }
      //  }
       
    },
    loadList(obj) {
      userPageList(obj).then(res=>{
        this.userList = res.data.records
        this.total = res.data.total
      })
    },
    search() {
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    currentChange(current) {
      this.pageParams.current = current;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.currentChange(1);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    click(obj,node) {
      this.districtids = []
      if(obj.type==5){
        this.pageParams.deptId = ''
        this.pageParams.addrssId = ''
        this.pageParams.addrId = ''
        this.pageParams.addrsId = obj.trueId
        this.pageParams.current = 1
        this.loadList(this.pageParams)
      }else if(obj.type==4){
        this.pageParams.deptId = ''
        this.pageParams.addrssId = ''
        this.pageParams.addrId = obj.trueId
        this.pageParams.current = 1
        this.pageParams.addrsId =''
        this.loadList(this.pageParams)
      }else if(obj.type==3) {
        this.pageParams.deptId = obj.trueId
        this.pageParams.addrsId = ''
        this.pageParams.addrssId = ''
        this.pageParams.current = 1
        this.pageParams.addrId = ''
        this.loadList(this.pageParams)
      }else if(obj.type == 6) {
        this.pageParams.deptId = ''
        this.pageParams.addrsId = ''
        this.pageParams.addrssId = obj.trueId
        this.pageParams.current = 1
        this.pageParams.addrId = ''
        this.loadList(this.pageParams)
        this.districtids = [
          {value:node.parent.parent.data.trueId,name:node.parent.parent.data.name},
          {value:node.parent.data.trueId,name:node.parent.data.name},
          {value:node.data.trueId,name:node.data.name}
        ]
      }
      else {
        this.pageParams.addrsId = ''
        this.pageParams.addrssId = ''
        this.pageParams.deptId = obj.trueId
        this.pageParams.current = 1
        this.pageParams.addrId = ''
        this.loadList(this.pageParams)
      }

    },
    statusChange(val, row) {
      const statusText = val === 0 ? "禁用" : "开启";
      this.$confirm(`是否${statusText}此用户账号?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      })
          .then(() => {
            usereditStatus({ id: row.id, generalStatus: val })
                .then(() => {
                  this.$message.success(`${statusText}成功`);
                  this.loadList(this.pageParams);
                })
                .catch((err) => {
                  this.$message.error(err.message);
                  row.generalStatus == 0
                      ? (row.generalStatus = 1)
                      : (row.generalStatus = 0);
                });
          })
          .catch(() => {
            row.generalStatus == 0
                ? (row.generalStatus = 1)
                : (row.generalStatus = 0);
          });
    },
    /**
     * 导出
     */
    async onDownExcel() {
      let { total, pageParams } = this;
      let requestArr = [];
      let obj = JSON.parse(JSON.stringify(pageParams));
      obj.current = 1;
      while (requestArr.length < total) {
        let e = await findpageList(obj);
        requestArr = [...requestArr, ...e.data.list];
        obj.current++;
      }
      let excelData = "\uFEFF";
      excelData += "用户名,用户姓名,部门,手机号码,状态,创建时间";
      excelData += "\n";
      requestArr.forEach((e) => {
        excelData += [
          "" + e.username,
          e.realName,
          e.homeName,
          e.phone,
          e.generalStatus === 1 ? "开启" : "禁用",
          e.createTime,
        ].join(",");
        excelData += "\n";
      });
      let date = new Date();
      let fileName = `用户管理表格-${date.getFullYear()}-${String(
          date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
      exportMethod(excelData, fileName);
    },
    /**
     * 获取角色字典
     */
    getRoleIdsDic() {
      // getRoleIds()
      //   .then(res => {
      //     this.roleArray_group = res.data
      //   })
      //   .catch(err => {
      //     this.roleArray_group = []
      //   })
    },
    getCompanyFromDic() {
      // getCompanyDic().then(res => {})
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 99%;
  border:10px solid #F3F4F7 ;
  background: #fff;
  .clears {
    display: flex;
    flex-direction: row;
    align-items: center;
    .buttons {
      height: 30px;
      margin-left: 10px;
    }
  }
  .TreeBox {
    padding: 20px;
    border-right: 1px solid #F1F1F1;
    background-color: #ffffff;
    .titlw{
      font-size: 18px;
      font-weight: bold;
      color: #303133;
      margin-bottom: 20px;
      span{
        width: 3px;
        height: 16px;
        border:1px solid #1082FF;
        margin-right:9px;
      }
    }
  }
  .filter-tree {
    height: calc(100vh - 270px);
    padding-top: 20px;
    overflow: auto;
  }
  .TableBox {
    padding: 10px 10px 0;
    height: calc(100vh - 150px);
    border-radius: 6px;
    background-color: #fff;
    .SearchBox {
      display: flex;
      flex-wrap: wrap;
      ::v-deep.el-form-item {
        margin-bottom: 0px;
      }
    }
    .selectType{
    ::v-deep .el-select{
      width: 130px;
      
    }
    .el-input-group--prepend{
        margin-top: 6px!important;
      }
    }
  }
  .TablePages {
    .AffirmBtn {
      ::v-deep span {
        height: 12px;
        line-height: 12px;
        min-width: 0px;
      }
    }
  }
  .EialogForm {
    .FormBox {
      padding: 0 100px;
      .NotRequired {
        ::v-deep .el-form-item__label {
          padding-left: 10px;
        }
        .el-select {
          width: 100%;
        }
      }
    }
  }
  .TableTool {
    display: flex;
    justify-content: space-between;
  }
}
.detil-code{
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
  margin-bottom: 20px;
}
.detil-code:before{
  content: '';
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007AFF;
  top: 0;
  left: 0;
}
/deep/.el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    // 设置颜色
    background-color: rgba(135, 206, 235, 0.2); // 透明度为0.2的skyblue，作者比较喜欢的颜色 
    color: #409eff; // 节点的字体颜色
    font-weight: bold; // 字体加粗
  }
</style>
